import React from "react";

import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";

import Page from "../components/internal-page";

const Collateral = () => {
  return (
    <Page title="Величина прожиточного минимума в Архангельской области для трудоспособного населения">
      <Paragraph1>
        Согласно п. 5.1. Правил предоставления микрозаймов одним из критериев
        предоставления Фондом займов субъектам малого и среднего
        предпринимательства является условие того, что среднемесячная заработная
        плата за каждый из 6 последних месяцев в расчете на одного штатного
        работника, работающего полный рабочий день, не должна быть ниже величины
        прожиточного минимума, установленного для трудоспособного населения
        Архангельской области.
      </Paragraph1>
      <Paragraph1>
        Величина прожиточного минимума в регионе утверждается постановлением
        Правительства Архангельской области:
      </Paragraph1>
      <HeadingLevel>
        <Heading>2022</Heading>
        <Paragraph1>Величина прожиточного минимума – 16000 &#8381;</Paragraph1>
        <Heading>2021</Heading>
        <Paragraph1>Величина прожиточного минимума – 15048 &#8381;</Paragraph1>
        <Heading>2020</Heading>
        <Paragraph1>IV квартал – 14507 &#8381;</Paragraph1>
        <Paragraph1>III квартал – 15048 &#8381;</Paragraph1>
        <Paragraph1>II квартал – 15026 &#8381;</Paragraph1>
        <Paragraph1>I квартал – 13873 &#8381;</Paragraph1>
        <Heading>2019</Heading>
        <Paragraph1>IV квартал – 13474 &#8381;</Paragraph1>
        <Paragraph1>III квартал – 14107 &#8381;</Paragraph1>
        <Paragraph1>II квартал – 14294 &#8381;</Paragraph1>
        <Paragraph1>I квартал – 13558 &#8381;</Paragraph1>
        <Heading>2018</Heading>
        <Paragraph1>IV квартал – 12896 &#8381;</Paragraph1>
        <Paragraph1>III квартал – 13149 &#8381;</Paragraph1>
        <Paragraph1>II квартал – 13009 &#8381;</Paragraph1>
        <Paragraph1>I квартал – 12489 &#8381;</Paragraph1>
        <Heading>2017</Heading>
        <Paragraph1>IV квартал – 12361 &#8381;</Paragraph1>
        <Paragraph1>III квартал – 13011 &#8381;</Paragraph1>
        <Paragraph1>II квартал – 13022 &#8381;</Paragraph1>
        <Paragraph1>I квартал – 12377 &#8381;</Paragraph1>
        <Heading>2016</Heading>
        <Paragraph1>IV квартал – 11878 &#8381;</Paragraph1>
        <Paragraph1>III квартал – 12095 &#8381;</Paragraph1>
        <Paragraph1>II квартал – 14319 &#8381;</Paragraph1>
        <Paragraph1>I квартал – 14376 &#8381;</Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default Collateral;
